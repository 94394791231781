import { AfterViewInit, Component, Input } from '@angular/core';
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent, Timestamp } from '../shared/types/csv-model';
import { VgStates } from '@videogular/ngx-videogular/core';
import CanvasDrawn from '../redrawing-canvas/canvas-drawn-model-class';
import { VideoService } from '../services/video.service';
import { VGApiServiceEx } from '../shared/types/VGApiService';
import { Subscription } from 'rxjs';
import { EyeTrackerEventHandler, MouseEventHandler, TrackerEventHandler } from './tracker-event-handler';

@Component({
  selector: 'app-tracker-data-display',
  template: '<app-redrawing-canvas [canvasDrawn]="canvasDrawn" />',
})

export class TrackerDataDisplayComponent implements AfterViewInit {
  @Input() api: VGApiServiceEx; //todo replace with service?

  private _mouseEvents: CSVRecordMouseEvent[];
  public get mouseEvents() {
    return this._mouseEvents;
  }
  @Input()
  public set mouseEvents(events: CSVRecordMouseEvent[]) {
    this._mouseEvents = events;
    if (this.mouseEventHandler) {
      this.mouseEventHandler.trackedEvents = events;
    }
  }

  private _eyeEvents: CSVRecordEyeTrackingEvent[];
  public get eyeEvents() {
    return this._eyeEvents;
  }
  @Input()
  public set eyeEvents(events: CSVRecordEyeTrackingEvent[]) {
    this._eyeEvents = events;
    if (this.eyeTrackerHandler) {
      this.eyeTrackerHandler.trackedEvents = events;
    }
  }

  private eyeTrackerHandler?: EyeTrackerEventHandler;
  private mouseEventHandler?: MouseEventHandler;

  private eyeTrackerCanvasDrawn: CanvasDrawn[] = [];
  private mouseTrackerCanvasDrawn: CanvasDrawn[] = [];

  private subscriptions: Subscription;

  eyeTrackTimer?: NodeJS.Timeout;
  mouseEventTimer?: NodeJS.Timeout;

  lastDrawnShape: CanvasDrawn;
  lastEyeTrackEvent?: Timestamp;
  lastMouseEvent?: CSVRecordMouseEvent;

  /// what we're gonna output to the canvas, the shapes we want drawn
  canvasDrawn: CanvasDrawn[] = [];

  public get currentTime(): number {
    if (!this.api) return 0;
    return this.api.currentTime;
  }

  public get isPaused(): boolean {
    if (!this.api) return true;
    if (this.api.state === VgStates.VG_PAUSED) return true;
    return false;
  }

  constructor(private videoService: VideoService) {
  }

  ngAfterViewInit() {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.videoService.api.subscribe((api) => this.hookToApi(api)));
  }

  private hookToApi(api: VGApiServiceEx) {
    if (!api) return;
    this.api = api;

    this.subscriptions.add(this.api.subscriptions.timeUpdate.subscribe(() => this.onVideoTimeUpdate()));
    this.subscriptions.add(this.api.subscriptions.seeked.subscribe((value: number) => this.onVideoSeek(value)));
    this.subscriptions.add(this.api.subscriptions.play.subscribe(() => this.onPlayVideo()));
    this.subscriptions.add(this.api.subscriptions.pause.subscribe(() => this.onPauseVideo()));
    this.subscriptions.add(this.api.subscriptions.ended.subscribe(() => this.stopVideoTimer()));
    this.subscriptions.add(this.api.subscriptions.abort.subscribe(() => this.onAbortVideo()));
  }

  onPlayVideo() {
    // all the tracking logic starts in onVideoTimeUpdate, but it doesn't start quite immediately, so to make it a little more responsive, we also start it here
    if (!this.eyeTrackerHandler && this.eyeEvents?.length) {
      this.eyeTrackerHandler = this.initializeEventHandler(this.eyeEvents, EyeTrackerEventHandler, this.updateEyeCanvasDrawn.bind(this));
    }

    if (!this.mouseEventHandler && this.mouseEvents?.length) {
      this.mouseEventHandler = this.initializeEventHandler(this.mouseEvents, MouseEventHandler, this.updateMouseCanvasDrawn.bind(this));
    }

    this.eyeTrackerHandler?.startTimer();
    this.mouseEventHandler?.startTimer();
  }

  private initializeEventHandler<T extends TrackerEventHandler<Timestamp>>(
    data: Timestamp[],
    Handler: new (...args: unknown[]) => T,
    callback: TrackerEventHandler<Timestamp>["setCanvasCallback"]
  ): T {
    return new Handler(
      data,
      this.canvasDrawn,
      this.currentTime,
      callback
    );
  }

  async updateCanvasDrawn() {
    this.canvasDrawn = [...this.eyeTrackerCanvasDrawn, ...this.mouseTrackerCanvasDrawn];
  }

  // todo figure out a more elegant way to do these 2 functions
  updateEyeCanvasDrawn() {
    if (this.eyeTrackerHandler) {
      this.eyeTrackerCanvasDrawn = this.eyeTrackerHandler.canvasDrawn;
    }
    this.updateCanvasDrawn();
  }

  updateMouseCanvasDrawn() {
    if (this.mouseEventHandler) {
      this.mouseTrackerCanvasDrawn = this.mouseEventHandler.canvasDrawn;
    }
    this.updateCanvasDrawn();
  }

  onPauseVideo() {
    this.stopTrackers();
    // console.log('canvas drawn:', this.canvasDrawn);
  }

  async onVideoSeek(newTime) {
    console.log('video seeked', newTime);
    this.eyeTrackerHandler?.updateToTime(this.currentTime);
    this.mouseEventHandler?.updateToTime(this.currentTime);
  }

  onAbortVideo() {
    this.stopTrackers(); // temp, check if multiple different signals are called when closing
    this.cleanupSubscriptions();
    console.log('video aborted');
  }

  stopVideoTimer() {
    this.stopTrackers();
    console.log('video stopped');
  };

  stopTrackers() {
    this.eyeTrackerHandler?.stopTimer();
    this.mouseEventHandler?.stopTimer();
  }

  cleanupSubscriptions() {
    this.subscriptions?.unsubscribe();
    this.subscriptions = null;
  }

  async onVideoTimeUpdate() {
    console.log('video time update', this.currentTime);
    if (this.eyeTrackerHandler) {
      this.eyeTrackerHandler.currentTime = this.currentTime;
    }
    if (this.mouseEventHandler) {
      this.mouseEventHandler.currentTime = this.currentTime;
    }

    if (this.eyeTrackerCanvasDrawn.length) {
      this.eyeTrackerCanvasDrawn = this.removeOldDraws(this.eyeTrackerCanvasDrawn);
    }
    if (this.mouseTrackerCanvasDrawn.length) {
      this.mouseTrackerCanvasDrawn = this.removeOldDraws(this.mouseTrackerCanvasDrawn);
    }

    // update with up-to-date canvasDrawn, likely with removed elements
    if (this.eyeTrackerHandler) {
      this.eyeTrackerHandler.canvasDrawn = this.eyeTrackerCanvasDrawn;
    }
    if (this.mouseEventHandler) {
      this.mouseEventHandler.canvasDrawn = this.mouseTrackerCanvasDrawn;
    }
  }

  private removeOldDraws(array: CanvasDrawn[]) {
    return array.filter((element) => element.deleteIn !== -1 && element.deleteIn > this.currentTime);
  }
};
