import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecordingUserConfig } from '../shared/types/user-config';
import { allFileTemplates, CachedFile, getDefaultFileName } from '../file-input/request-files';
import { FileDataType } from '../file-input/recorded-data-enums';
import { MatSnackBar } from '@angular/material/snack-bar';

// TODO split this up into recording/file input services
@Injectable({ providedIn: 'root' })
export class CommunicationService {
  private _museReady = new BehaviorSubject(false);
  private _consoleAppReady = new BehaviorSubject(false);
  /// true if everything we need is ready to start recording
  private _canStartRecording = new BehaviorSubject(false);
  /// Whether or not the emotion recognition component is ready
  private _emotionRecognitionReady = new BehaviorSubject(false);
  /// if we are actively recording right now
  private _isRecording = new BehaviorSubject(false);
  /// settings that the user defines
  private _recordingSettings = new BehaviorSubject<RecordingUserConfig>({
    eyeTracking: true,
    mouseAndKeyboard: true,
    muse: true,
    screenRecording: true,
    faceRecording: true
  });
  // cached files to save between page changes
  private _cachedFiles = new BehaviorSubject<CachedFile[]>([]);

  readonly museReady = this._museReady.asObservable();
  readonly consoleAppReady = this._consoleAppReady.asObservable();
  readonly canStartRecording = this._canStartRecording.asObservable();
  readonly emotionRecognitionReady = this._emotionRecognitionReady.asObservable();
  readonly isRecording = this._isRecording.asObservable();
  readonly recordingSettings = this._recordingSettings.asObservable();
  readonly cachedFiles = this._cachedFiles.asObservable();

  constructor(private snackbar: MatSnackBar) { }

  async changeMuseReady(readyBoolean: boolean) {
    if (readyBoolean === this._museReady.value) return false;
    this._museReady.next(readyBoolean);
    this.changeReadyToRecord(readyBoolean);
    console.log('muse ready:', readyBoolean);
    return true;
  }

  async changeConsoleAppReady(readyBoolean: boolean) {
    if (readyBoolean === this._consoleAppReady.value) return false;
    this._consoleAppReady.next(readyBoolean);
    this.changeReadyToRecord(readyBoolean);
    console.log('console app ready', readyBoolean);
    return true;
  }

  async changeEmotionRecognitionReady(camBoolean: boolean) {
    if (camBoolean === this._emotionRecognitionReady.value) return false;
    this._emotionRecognitionReady.next(camBoolean);
    this.changeReadyToRecord(camBoolean);
    console.log('emotion recognition ready', camBoolean);
    return true;
  }

  async changeReadyToRecord(recordBoolean: boolean) {
    if (!this.isValidRecordingChange()) {
      if (this._canStartRecording.value) {
        this._canStartRecording.next(false);
      }
      return false;
    };
    if (recordBoolean === this._canStartRecording.value) return false;
    console.log('record ready', recordBoolean);
    this._canStartRecording.next(recordBoolean);
    return true;
  }

  private isValidRecordingChange() {
    const settings = this._recordingSettings.value;
    // these 3 require the console app
    if ((settings.eyeTracking || this.consoleAppRequired()) && !this._consoleAppReady.value) return false;
    if (settings.faceRecording && !this._emotionRecognitionReady.value) return false;
    if (settings.muse && !this._museReady.value) return false;
    // we gotta check that ONE of the settings is true
    if (Object.values(settings).every(val => !val)) return false;
    return true;
  }

  async changeIsRecording(recordingBoolean: boolean) {
    if (recordingBoolean === this._isRecording.value) return false;
    if (recordingBoolean && !this._canStartRecording.value) return false;
    console.log('isRecording', recordingBoolean);
    this._isRecording.next(recordingBoolean);
    return true;
  }

  async changeUserSettings(settings: Partial<RecordingUserConfig>) {
    const newSettings = { ...this._recordingSettings.value, ...settings };
    this._recordingSettings.next(newSettings);
    this.changeReadyToRecord(true);
    return true;
  }

  /// get a single user setting observable
  getUserSetting(setting: keyof RecordingUserConfig) {
    return this._recordingSettings.pipe(map(settings => settings[setting]), distinctUntilChanged());
  }

  async cacheFile(blob: Blob | File, flag: FileDataType) {
    const template = allFileTemplates.find(t => t.flag === flag);
    // if we get a blob we can turn it into a file easily
    const file = blob instanceof File ? blob : new File([blob], getDefaultFileName(template), { type: `.${template.fileTypes[0]}` });
    let parsedData: unknown = null;
    if (template.processCallback) {
      parsedData = await template.processCallback(file);
    }
    if (template.isValidCallback && !template.isValidCallback(file)) {
      this.snackbar.open(`Uploaded file is not a valid ${template.displayName} file.`, 'Dismiss');
      return false;
    }
    const cachedFile: CachedFile = {
      file,
      defaultFileName: template.defaultFileName,
      displayName: template.displayName,
      flag: template.flag,
      fileTypes: template.fileTypes,
      parsedData
    };
    return this.addCachedFile(cachedFile);
  }

  private async addCachedFile(file: CachedFile) {
    // Filter out out the file that has the same flag, so we don't have duplicates
    const oldCache = this._cachedFiles.value.filter(f => f.flag !== file.flag);
    this._cachedFiles.next([...oldCache, file]);
    return true;
  }

  /// get cached file observable, useful if you only want to subscribe to one file
  getCachedFile(flag: FileDataType) {
    // only allow one flag at a time
    const isSingleBitflag = (flag & (flag - 1)) === 0;
    if (!isSingleBitflag) throw new Error('flag must be a single bitflag');
    return this.cachedFiles.pipe(
      map(files => files.find(f => f.flag === flag)),
      distinctUntilChanged((prev, curr) => prev?.file === curr?.file)
    );
  }

  getCachedFiles() {
    return this.cachedFiles.pipe(
      map(files => files.map(f => f.flag)),
      distinctUntilChanged(
        (prev, curr) => prev.length === curr.length && prev.every((val, i) => val === curr[i])
      )
    );
  }

  consoleAppRequired() {
    const settings = this._recordingSettings.value;
    return settings.eyeTracking || settings.screenRecording || settings.mouseAndKeyboard;
  }

}
