<div id="videoContainer">
  <div *ngIf="modeLoadPromise; else readyCamera">
    <p>Setting up Emotion Recognition Models</p>
    <mat-progress-bar mode="determinate" [value]="modelLoadStatus()" />
  </div>
  <ng-template #readyCamera>
    <p id="emotionCameraStatusPassive" *ngIf="!cameraReady">Readying camera and trying to detect a face</p>
    <p id="emotionCameraStatusActive" *ngIf="cameraReady">Camera ready</p>
  </ng-template>
  <div style="position: relative;">
    <video id="video" width="640" height="480" autoplay></video>
  </div>
</div>
