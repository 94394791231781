import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import Utils from '../utils';
import { CSVRecordEmotions, CSVRecordEmotionsAnalyzed } from '../shared/types/csv-model';
import { TimeStampModel } from '../shared/types/timestamp-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emotionFileAnalyzed } from '../file-input/request-files-presets';
import { CachedFile, getDefaultFileName } from '../file-input/request-files';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { FileDataType } from '../file-input/recorded-data-enums';

@Component({
  selector: 'app-process-emotions-file',
  templateUrl: './process-emotions-file.component.html',
  styleUrls: ['./process-emotions-file.component.scss']
})

export class ProcessEmotionsFileComponent implements OnInit, OnDestroy {
  private records: CSVRecordEmotions[] = [];
  private cleanRecords: CSVRecordEmotions[] = [];
  private timestampArr: string[] = [];
  private timestampAsModelArr: TimeStampModel[] = [];
  private csvRecordsArray: string[] = [];

  private isEmotionFile = false;

  private timeStampsAsSecondsArr: number[];
  private strongestEmotion: string;
  private secondStrongestEmotion: string;
  private thirdStrongestEmotion: string;
  private analyzedData: string[][];

  private subscription = new Subscription();


  @Output() sendEmotionsDataEvent = new EventEmitter<ProcessEmotionsFileComponent["analyzedData"]>();

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.subscription.add(this.comData.getCachedFile(FileDataType.EmotionDataSynced).subscribe((file) => {
      if (file) {
        this.onFileUpload(file);
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  async onFileUpload(cachedFile: CachedFile) {
    const u = new Utils();

    if (!u.isValidCSVFile(cachedFile.file)) {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
      this.records = [];
    }

    const csvData = await cachedFile.file.text();
    this.csvRecordsArray = csvData.split(/\r\n|\n/);
    const headersRow = u.getHeaderArray(this.csvRecordsArray);

    // todo full validation via file templates
    if (headersRow[1] !== 'angry') {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
      throw new Error('Invalid file');
    }

    this.analyzedData = [];

    // read CSV
    this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);

    // remove NaN rows
    this.cleanRecords = this.removeNaNRows(this.records);

    // parse timestamps
    this.parseTimestampsAsModels(this.timestampArr);

    // timestamps as seconds
    this.timeStampsAsSecondsArr = this.convertTimestampsToSeconds(this.timestampAsModelArr);

    this.analyzedData = this.createAnalyzedEmotionsCSV(this.cleanRecords);
    this.sendEmotionsDataEvent.emit(this.analyzedData);

    this.saveToCSV(this.analyzedData);
  };



  removeNaNRows(records: ProcessEmotionsFileComponent["cleanRecords"]) {
    const cleanedDataArray = [];
    for (let i = 0; i < records.length; i++) {
      if (records[i].angryColumn !== 'NaN' && records[i].angryColumn !== '0') {
        cleanedDataArray.push(records[i]);
        this.timestampArr.push(records[i].timestampColumn);
      }
    }
    return cleanedDataArray;
  }

  analyzeEmotions(emotions: CSVRecordEmotions) {
    const emotionScoresMap = new Map<string, number>();
    emotionScoresMap.set('angry', parseFloat(emotions.angryColumn));
    emotionScoresMap.set('sad', parseFloat(emotions.sadColumn));
    emotionScoresMap.set('disgusted', parseFloat(emotions.disgustedColumn));
    emotionScoresMap.set('surprised', parseFloat(emotions.surprisedColumn));
    emotionScoresMap.set('happy', parseFloat(emotions.happyColumn));
    emotionScoresMap.set('neutral', parseFloat(emotions.neutralColumn));

    this.strongestEmotion = this.findMaxScore(emotionScoresMap)[0];
    emotionScoresMap.delete(this.strongestEmotion);
    this.secondStrongestEmotion = this.findMaxScore(emotionScoresMap)[0];
    if (this.findMaxScore(emotionScoresMap)[1] >= 0.05) {
      emotionScoresMap.delete(this.secondStrongestEmotion);
      this.thirdStrongestEmotion = this.findMaxScore(emotionScoresMap)[0];
      if (this.findMaxScore(emotionScoresMap)[1] >= 0.05) {
        emotionScoresMap.delete(this.thirdStrongestEmotion);
      } else {
        this.thirdStrongestEmotion = '';
      }
    } else {
      this.secondStrongestEmotion = '';
      this.thirdStrongestEmotion = '';
    }
  }

  findMaxScore(emotionScoresMap: Map<string, number>) {
    return [...emotionScoresMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a);
  }

  createAnalyzedEmotionsCSV(cleanedDataArray: ProcessEmotionsFileComponent["cleanRecords"]) {
    const analyzedDataArray: ProcessEmotionsFileComponent["analyzedData"] = [];
    for (let i = 0; i < cleanedDataArray.length; i++) {
      let emotion: CSVRecordEmotions = new CSVRecordEmotions();
      emotion = cleanedDataArray[i];
      const csvRecordAnalyzed: CSVRecordEmotionsAnalyzed = new CSVRecordEmotionsAnalyzed();
      csvRecordAnalyzed.timestampColumn = cleanedDataArray[i].timestampColumn;
      csvRecordAnalyzed.timeStampsAsSecondsColumn = this.timeStampsAsSecondsArr[i];
      csvRecordAnalyzed.angryColumn = cleanedDataArray[i].angryColumn;
      csvRecordAnalyzed.sadColumn = cleanedDataArray[i].sadColumn;
      csvRecordAnalyzed.disgustedColumn = cleanedDataArray[i].disgustedColumn;
      csvRecordAnalyzed.surprisedColumn = cleanedDataArray[i].surprisedColumn;
      csvRecordAnalyzed.happyColumn = cleanedDataArray[i].happyColumn;
      csvRecordAnalyzed.neutralColumn = cleanedDataArray[i].neutralColumn;
      this.analyzeEmotions(emotion);
      csvRecordAnalyzed.strongestEmotionColumn = this.strongestEmotion;
      csvRecordAnalyzed.secondStrongestEmotionColumn = this.secondStrongestEmotion;
      csvRecordAnalyzed.thirdStrongestEmotionColumn = this.thirdStrongestEmotion;
      analyzedDataArray.push([csvRecordAnalyzed.timestampColumn, String(csvRecordAnalyzed.timeStampsAsSecondsColumn), csvRecordAnalyzed.angryColumn, csvRecordAnalyzed.sadColumn, csvRecordAnalyzed.disgustedColumn, csvRecordAnalyzed.surprisedColumn,
      csvRecordAnalyzed.happyColumn, csvRecordAnalyzed.neutralColumn, csvRecordAnalyzed.strongestEmotionColumn, csvRecordAnalyzed.secondStrongestEmotionColumn, csvRecordAnalyzed.thirdStrongestEmotionColumn]);
    }
    return analyzedDataArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: ProcessEmotionsFileComponent["csvRecordsArray"], headerLength: number) {
    // Emotion
    const csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const currentRecord = csvRecordsArray[i].split(',');
      if (currentRecord.length === headerLength) {
        const csvRecord = new CSVRecordEmotions();
        csvRecord.timestampColumn = currentRecord[0].trim();
        csvRecord.angryColumn = currentRecord[1].trim();
        csvRecord.sadColumn = currentRecord[2].trim();
        csvRecord.disgustedColumn = currentRecord[3].trim();
        csvRecord.surprisedColumn = currentRecord[4].trim();
        csvRecord.happyColumn = currentRecord[5].trim();
        csvRecord.neutralColumn = currentRecord[6].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  parseTimestampsAsModels(timestampArr: string[]) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = parseFloat(timestampNS[0]);
      timestampAsModel.minutes = parseFloat(timestampNS[1]);
      // Round up milliseconds up to standardize with Morae timetamps
      // const roundedTimestampSeconds = timestampNS[3].toFixed(2);
      // const timestampSecondsSplitNS = roundedTimestampSeconds[2].split('.');
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = parseFloat(timestampSecondsSplitNS[0]);
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  convertTimestampsToSeconds(timestampAsModelArr: TimeStampModel[]) {
    const timestampsAsSecondsArr = [];

    // convert timestamps in array into seconds
    timestampAsModelArr.forEach(element => {
      const timeStampSeconds = parseInt(element.seconds.toString()) + parseInt(element.minutes.toString()) * 60 + parseInt(element.hours.toString()) * 3600;
      timestampsAsSecondsArr.push(timeStampSeconds);
    });
    return timestampsAsSecondsArr;
  }

  saveToCSV(updatedRecords: ProcessEmotionsFileComponent["analyzedData"]) {
    const a = document.createElement('a');
    let headers = [];
    if (this.isEmotionFile) {
      headers = ['time', 'time in seconds', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral', 'strongest', '2nd strongest', '3rd strongest'];
      a.download = getDefaultFileName(emotionFileAnalyzed);
      this.isEmotionFile = false;
    }
    /*else if (this.isMuseFile) {
      headers = ['time', 'delta_TP9', 'delta_AF7', 'delta_AF8', 'delta_TP10', 'theta_TP9', 'theta_AF7', 'theta_AF8', 'theta_TP10', 'alpha_TP9', 'alpha_AF7', 'alpha_AF8', 'alpha_TP10', 'beta_TP9', 'beta_AF7', 'beta_AF8', 'beta_TP10', 'gamma_TP9', 'gamma_AF7', 'gamma_AF8', 'gamma_TP10'];
      a.download = 'muse_synced.csv';
      this.isMuseFile = false;
    }*/
    // const headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
    const csvData = headers + '\n' + updatedRecords.map(item => item.join(',')).join('\n');
    const file = new Blob([csvData], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    document.body.appendChild(a);
    a.download = getDefaultFileName(emotionFileAnalyzed);
    a.click();
    document.body.removeChild(a);
    this.cleanRecords = [];
    this.timestampArr = [];
    this.timestampAsModelArr = [];
  }
}

