<div id="present-data-container">
  <div style="display: flex; align-items: center; gap: 2rem;">
    <div>
      <h2>Data Presentation</h2>
      <p>
        Present and visualize the data you have recorded.
      </p>

      <p>
        Upload the video file you want to present and the data files you want to visualize. <br>
        Circles and lines showcase eye tracking, and triangles showcase mouse clicks
      </p>
    </div>
    <app-file-input style="justify-content: flex-start;" [requestFileFlags]=" requestInputs" />
  </div>
  <section id="video-upload-component">
    <app-keyboard-present [keyboardEvents]="keyboardEventRecords" />

    <app-steppable-video #videoPlayer [videoUrl]="videoUrl" (playerReady)="onVideoReady($event)" (ended)="onVideoEnd()">
      <app-tracker-data-display class="overlay" [mouseEvents]="mouseEventRecords"
        [eyeEvents]="eyeTrackingEventRecords" />
    </app-steppable-video>
  </section>
</div>
