import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { allFileTemplates, CachedFile, getDefaultFileName, RequestFile } from './request-files';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDataType } from './recorded-data-enums';
import { CommunicationService } from '../services/communication.service';
import { Subscription } from 'rxjs';

interface LocalCache {
  flag: FileDataType;
  // actual filename may differ from the default filename
  fileName: string;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent implements OnInit, OnDestroy {
  @Input() public style: string;
  @Input() public uploadText: Partial<Record<FileDataType, string>>;
  @Input() public extraButtons: { text: string, callback: () => void; }[];


  private _requestFileFlags: FileDataType;
  @Input()
  get requestFileFlags() {
    return this._requestFileFlags;
  }

  set requestFileFlags(flags: FileDataType) {
    if (this._requestFileFlags === flags) return;
    this.onRequestFileFlagsChange(flags);
    this._requestFileFlags = flags;
  }

  public requestFiles: RequestFile[];

  @Output() fileUpload = new EventEmitter<File>();

  // cached files that are stored in the service, differentiated by the flag
  cachedFiles: LocalCache[] = [];
  subscription = new Subscription();

  protected readonly fileReader = new FileReader();

  constructor(private snackbar: MatSnackBar, private comData: CommunicationService) { }

  // check if we can load any cached files on component init
  ngOnInit() {
    this.subscription.add(this.comData.cachedFiles.subscribe((files) => this.onFileCacheUpdate(files)));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  private async onRequestFileFlagsChange(flags: FileDataType) {
    this.requestFiles = allFileTemplates.filter((preset) => {
      return flags & preset.flag;
    });

    if (!this.uploadText || !Object.entries(this.uploadText).length) {
      this.uploadText = {} as FileInputComponent['uploadText'];
      this.requestFiles.forEach((file) => {
        this.uploadText[file.flag] = `Upload ${getDefaultFileName(file, true)}`;
      });
    }
  }

  onFileCacheUpdate(files: CachedFile[]) {
    this.cachedFiles = files.map((file) => ({ flag: file.flag, fileName: file.file.name }));
  }

  isFileCached(file: RequestFile) {
    return this.cachedFiles.find((cachedFile) => cachedFile.flag === file.flag);
  }

  async onFileUpload(event: Event, requestFile: RequestFile) {
    if (!event.target || !(event.target instanceof HTMLInputElement)) {
      this.snackbar.open('Error with uploading file', 'Dismiss');
      throw new Error('Event target is not an instance of HTMLInputElement');
    }
    if (!event.target?.files.length) {
      this.snackbarError('No files selected');
      return;
    }
    if (event.target.files.length > 1) {
      this.snackbarError('Multiple file upload not supported');
      return;
    }

    const file = event.target.files[0];
    // validate the file extension
    if (!requestFile.fileTypes.includes(file.name.split('.').pop())) {
      this.snackbarError(`Invalid file type. Expected: ${this.getFileExtension(requestFile.fileTypes)}`);
      return;
    }
    this.fileUpload.emit(file);
    this.comData.cacheFile(file, requestFile.flag);

  }

  snackbarError(error: string, action = 'Dismiss') {
    this.snackbar.open(error, action);
    throw new Error(error);
  }


  trackByDefaultFileName(index: number, file: RequestFile) {
    return file.defaultFileName;
  }

  getFileExtension(fileExtensions: string[]) {
    const dotExtensions = fileExtensions.map(string => "." + string);
    return dotExtensions.join();
  }
}
