<mat-card class="input-files" [style]="style" *ngIf="requestFiles?.length">
  <div *ngFor="let file of requestFiles; trackByDefaultFileName:">
    <h3>{{ uploadText[file.flag] }}</h3>

    <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
      Choose file
    </button>

    <button *ngFor="let button of extraButtons" mat-raised-button color="primary" (click)="button.callback()">
      {{ button.text }}
    </button>

    <p class="csv-file-name" *ngIf="isFileCached(file)">{{ isFileCached(file).fileName }}</p>

    <input #fileInput hidden type="file" name="Upload {{file.fileTypes.join('/')}} file"
      (change)="onFileUpload($event, file)" [accept]="getFileExtension(file.fileTypes)" />

  </div>
