import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { CommunicationService } from './services/communication.service';


interface RouteLinks {
  label: string;
  link: string;
  index?: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  routeLinks: RouteLinks[] = [
    {
      label: 'Recording',
      link: './recording',
    }, {
      label: 'Converting',
      link: './converting',
    }, {
      label: 'Data processing',
      link: './clean-data',
    },
    {
      label: 'Present results',
      link: './present-data',
    }
  ];
  activeLinkIndex = -1;

  constructor(private router: Router, private comData: CommunicationService) {
    this.routeLinks = this.routeLinks.map((link, index) => {
      link.index = index;
      return link;
    });
  }

}
