

// bitfield so we don't use too many booleans, used for deciding when to override the cache
export enum FileDataType {
  None = 0,
  ProjectData = 1 << 0,
  MouseEventsSynced = 1 << 1,
  MouseEvents = 1 << 2,
  KeyboardEvents = 1 << 3,
  KeyboardEventsSynced = 1 << 4,
  EmotionData = 1 << 5,
  EmotionDataSynced = 1 << 6,
  EmotionDataAnalyzed = 1 << 7,
  MuseData = 1 << 8,
  MuseDataSynced = 1 << 9,
  EyeTracking = 1 << 10,
  EyeTrackingSynced = 1 << 11,
  ScreenVideo = 1 << 12,
  CameraVideo = 1 << 13,
  All = ~(0 << 14)
}
