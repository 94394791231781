import { FileDataType } from './recorded-data-enums';
import { RequestFile } from './request-files';
// recorded-data-input.component.ts uses ALL imported files from this file to handle caching via flags

// the most important file, sets the standard for converting timestamps of all the other files, and holds flags
export const projectDataFile: RequestFile<string> = {
  defaultFileName: 'events_data',
  displayName: 'Project Data',
  fileTypes: ['csv'], // first index is considered default
  flag: FileDataType.ProjectData,
  // we don't just textify each file because there's also video files, and that can get expensive
  processCallback: async (file: File) => {
    return await file.text();
  },
  isValidCallback: (file: File) => {
    return true;
  }
};

export const screenFile: RequestFile = {
  defaultFileName: 'screen',
  displayName: 'Screen Video File',
  fileTypes: ['mp4'],
  flag: FileDataType.ScreenVideo,
};

export const cameraFile: RequestFile = {
  defaultFileName: 'camera',
  displayName: 'Camera Video File',
  fileTypes: ['mp4'],
  flag: FileDataType.CameraVideo,
};

export const keyboardEventsFile: RequestFile<string> = {
  defaultFileName: 'keyboard_events',
  displayName: 'Keyboard Events',
  fileTypes: ['csv'],
  flag: FileDataType.KeyboardEvents,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const museFile: RequestFile<string> = {
  defaultFileName: 'muse',
  displayName: 'Muse Data',
  fileTypes: ['csv'],
  flag: FileDataType.MuseData,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const emotionFile: RequestFile<string> = {
  defaultFileName: 'emotion_raw',
  displayName: 'Raw Emotion Data',
  fileTypes: ['csv'],
  flag: FileDataType.EmotionData,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const museFileSynced: RequestFile<string> = {
  defaultFileName: 'muse_synced',
  displayName: 'Muse Data Synced',
  fileTypes: ['csv'],
  flag: FileDataType.MuseDataSynced,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const emotionFileSynced: RequestFile = {
  defaultFileName: 'emotion_synced',
  displayName: 'Emotion Data Synced',
  fileTypes: ['csv'],
  flag: FileDataType.EmotionDataSynced,
};

export const emotionFileAnalyzed: RequestFile = {
  defaultFileName: 'emotion_analyzed',
  displayName: 'Emotion Data Analyzed',
  fileTypes: ['csv'],
  flag: FileDataType.EmotionDataAnalyzed,
};

export const keyboardEventsSyncedFile: RequestFile = {
  defaultFileName: 'keyboard_events_synced',
  displayName: 'Keyboard Events Synced',
  fileTypes: ['csv'],
  flag: FileDataType.KeyboardEventsSynced,
};

export const mouseEvents: RequestFile<string> = {
  defaultFileName: 'mouse_events',
  displayName: 'Mouse Events',
  fileTypes: ['csv'],
  flag: FileDataType.MouseEvents,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const mouseEventsSynced: RequestFile = {
  defaultFileName: 'mouse_events_synced',
  displayName: 'Mouse Events Synced',
  fileTypes: ['csv'],
  flag: FileDataType.MouseEventsSynced,
};

export const eyeTrackingFile: RequestFile<string> = {
  defaultFileName: 'eye_tracking_samples',
  displayName: 'Eye Tracking Samples',
  fileTypes: ['csv'],
  flag: FileDataType.EyeTracking,
  processCallback: async (file: File) => {
    return await file.text();
  }
};

export const eyeTrackingFileSynced: RequestFile = {
  defaultFileName: 'eye_tracking_synced',
  displayName: 'Eye Tracking Samples Synced',
  fileTypes: ['csv'],
  flag: FileDataType.EyeTrackingSynced,
};
