<section id="converter-container">
  <h1>Timestamp syncronizer converter</h1>
  <p>
    Upload your unsynced data files here to syncronize them, to allow them to be usable in Present Results
  </p>
  <p>
    This will convert their unix timestamps to syncronized
    <label class="codeblock">hours:minutes:seconds.milliseconds</label>, eg <label
      class="codeblock">0:00:12.232</label>,<br>
    allowing them to be used in the Present Results section
  </p>

  <p id="timestampText"></p>
  <app-file-input style="flex-direction: column;" [requestFileFlags]="requestInputs" [uploadText]="uploadMessage" />

</section>
